import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'

import { TemplateContextProvider } from 'components/post/TemplateProvider'
import { PlacesContextProvider } from 'components/post/PlacesProvider'
import { dataStore } from "state/store-zustand";
import useContextTheme from 'components/utils/useContextTheme'


import Header from "components/header"

import AddPlace from 'components/forms/addPlaceForm'
import VibemapSearchProvider from 'components/utils/search-context/Provider'
import VibemapSearchField from 'components/search/Field'
import VibemapSearchParameterPersister from 'components/search/ParameterPersister'

import '../styles/search.scss'
import 'components/forms/form.scss'

function SearchPage({ data, location, ...props }) {

  const [title, setTitle] = useState('Add Business!')
  const [message, setMessage] = useState(<>Search to add or update a place or event to Vibemap. Send any questions or suggestions. <a href={`mailto:info@vibemap.com`}>info@vibemap.com</a></>)
  const [clearForm, setClearForm] = useState(undefined)
  const [from, setFrom] = useState(location.state ? location.state.from : ``)

  const setPlaceCurrent = dataStore((state) => state.setPlaceCurrent)

  const themeContext = useContextTheme()
  const { theme, themeClass } = themeContext

  const handleClick = (event, data) => {
    //console.log('handleClick ', event, data)
    if (data.details) {
      setPlaceCurrent(data.details)
    }
  }

  const handleSubmit = (place) => {
    console.log('TODO: handleSubmit ', place);
    setClearForm(true)
    setTitle('Thanks for helping us!')
    setMessage(<p>Vibemap will include this business in our city pages, vibe guides, and other content.</p>)
  }

  const maxWidth = 1000
  const pageStyle = {
    margin: `0 auto`,
    maxWidth: `${maxWidth}px`
  }

  return (
    <>
      <TemplateContextProvider tryLocation={true}>
        <PlacesContextProvider>
          <main className="page add-place" style={pageStyle}>
            <Header />
            <div className='container'>
              <div className='text has-bottom-padding'>
                <h2>{title}</h2>
                <p>{message}</p>
              </div>

              <VibemapSearchProvider
                doAutoSearch
                storedCities={data.allWpCity.nodes}
                preferStoredCities
                showGuides={false} >
                <div className='container'>
                  <fieldset>
                    <VibemapSearchParameterPersister />
                    <VibemapSearchField
                      autoFocus
                      autoComplete
                      onClick={handleClick}
                      placeholder={`Type place name or address`}
                      shouldClearForm={clearForm}
                      showCities={false}
                      showGuides={false}
                      searchGeocoder />
                  </fieldset>
                  <AddPlace onSubmit={handleSubmit} />
                </div>
              </VibemapSearchProvider>
            </div>
          </main>
        </PlacesContextProvider>
      </TemplateContextProvider>
    </>
  )
}

export const pageQuery = graphql`
query {
  allWpCity {
    nodes {
      title
      link
      uri
      cityDetails {
        description
        icon {
          localFile {
            publicURL
          }
        }
      }
    }
  }
}
`
SearchPage.whyDidYouRender = true
export default SearchPage